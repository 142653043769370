var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Organizingactivities" },
    [
      _c("div", { staticClass: "Organizingactivities_warp" }, [
        _c("div", { staticClass: "Organizingactivities_contain" }, [
          _c("h3", [
            _vm._v(_vm._s(_vm.$t("OrganizingActivities.Input.title")))
          ]),
          _c("div", { staticClass: "Essentialinformation" }, [
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "company" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      "* " + _vm._s(_vm.$t("ApplicationExhibition.Company"))
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.application.company,
                          expression: "application.company"
                        }
                      ],
                      staticClass: "input",
                      class: { showborder: _vm.thiscompany == true },
                      attrs: { type: "text", name: "", id: "company" },
                      domProps: { value: _vm.application.company },
                      on: {
                        blur: _vm.changecompany,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.application,
                            "company",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "after" })
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "Contacts" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      "* " + _vm._s(_vm.$t("ApplicationExhibition.Contacts"))
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.application.contacts,
                          expression: "application.contacts"
                        }
                      ],
                      staticClass: "input",
                      class: { showborder: _vm.thiscontacts == true },
                      attrs: { type: "text", name: "", id: "Contacts" },
                      domProps: { value: _vm.application.contacts },
                      on: {
                        blur: _vm.changecontacts,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.application,
                            "contacts",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "after" })
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "Mobilephone" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$route.params.lang == "zh" ? "*" : "") +
                        " " +
                        _vm._s(_vm.$t("ApplicationExhibition.Mobilephone"))
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.application.mobilephone,
                          expression: "application.mobilephone"
                        }
                      ],
                      staticClass: "input",
                      class: { showborder: _vm.thismobilephone == true },
                      attrs: { type: "text", name: "", id: "Mobilephone" },
                      domProps: { value: _vm.application.mobilephone },
                      on: {
                        blur: _vm.changemobilephone,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.application,
                            "mobilephone",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "after" })
                  ])
                ])
              ])
            ]),
            _vm.$route.params.lang == "en"
              ? _c("div", { staticClass: "label-root" }, [
                  _c("label", { attrs: { for: "mailbox" } }, [
                    _c("div", { staticClass: "label-container" }, [
                      _c("span", [
                        _vm._v(
                          "* " + _vm._s(_vm.$t("ApplicationExhibition.Mailbox"))
                        )
                      ]),
                      _c("div", { staticClass: "input-container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.application.mailbox,
                              expression: "application.mailbox"
                            }
                          ],
                          staticClass: "input",
                          class: { showborder: _vm.thismailbox == true },
                          attrs: { type: "text", name: "", id: "mailbox" },
                          domProps: { value: _vm.application.mailbox },
                          on: {
                            blur: _vm.changemailbox,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.application,
                                "mailbox",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("div", { staticClass: "after" })
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "label-root" }, [
              _c(
                "label",
                {
                  staticClass: "VerificationCode",
                  attrs: { for: "VerificationCode" }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("span", [
                      _vm._v(
                        "* " + _vm._s(_vm.$t("Newedition.VerificationCode"))
                      )
                    ]),
                    _c("div", { staticClass: "input-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.application.verificationCode,
                            expression: "application.verificationCode"
                          }
                        ],
                        staticClass: "VerificationCode input",
                        class: { showborder: _vm.thisverificationCode == true },
                        attrs: {
                          type: "text",
                          name: "",
                          id: "VerificationCode"
                        },
                        domProps: { value: _vm.application.verificationCode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.application,
                              "verificationCode",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("div", { staticClass: "after" })
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.codeMsg,
                          expression: "codeMsg"
                        }
                      ],
                      staticClass: "btn",
                      attrs: { type: "button", disabled: _vm.codeDisabled },
                      domProps: { value: _vm.codeMsg },
                      on: {
                        click: _vm.Verification,
                        blur: _vm.changeverificationCode,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.codeMsg = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              )
            ]),
            _vm.$route.params.lang == "zh"
              ? _c("div", { staticClass: "label-root" }, [
                  _c("label", { attrs: { for: "mailbox" } }, [
                    _c("div", { staticClass: "label-container" }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("ApplicationExhibition.Mailbox"))
                        )
                      ]),
                      _c("div", { staticClass: "input-container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.application.mailbox,
                              expression: "application.mailbox"
                            }
                          ],
                          staticClass: "input",
                          class: { showborder: _vm.thismailbox == true },
                          attrs: { type: "text", name: "", id: "mailbox" },
                          domProps: { value: _vm.application.mailbox },
                          on: {
                            blur: _vm.changemailbox,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.application,
                                "mailbox",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("div", { staticClass: "after" })
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "SimpleDescription" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("OrganizingActivities.Input.simpleDescription")
                      )
                    )
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.application.description,
                        expression: "application.description"
                      }
                    ],
                    staticClass: "Simple ",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { name: "", id: "SimpleDescription" },
                    domProps: { value: _vm.application.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.application,
                          "description",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "Submission", on: { click: _vm.Submission } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("SponsorShipText.submit")) +
                  "\n            "
              )
            ]
          )
        ])
      ]),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }